import { useRouter } from 'next/router';
import includes from 'lodash/includes';

import { IconsEnum } from '../../../../../assets/icons/types';

import { SearchFilterNanoID } from '../../../../searchFilters/searchFiltersTypes';

import { FetchMessagesFilters } from '../../../../messages/messagesTypes';
import { FetchTasksFilters } from '../../../../tasks/tasksTypes';
import { FetchProjectsFilters } from '../../../../projects/projectsTypes';

import { SecondaryMenuWrapper } from '../../../../../app/components/menus/SecondaryMenuWrapper';
import { SearchFiltersMenuDropdown } from '../../../../searchFilters/components/menus/SearchFiltersMenuDropdown';
import { MenuTitle } from '../MenuTitle';
import { MenuPlainLink } from '../MenuPlainLink';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import { MessagePath } from '../../../../messages/MessagePath';
import { TaskPath } from '../../../../tasks/TaskPath';
import { ProjectPath } from '../../../../projects/ProjectPath';

import {
  evaluationsKeys,
  menuKeys,
  taskMemberInvitesKeys,
  resultsKeys,
  invoicesKeys,
  messagesKeys
} from '../../../../../locales/keys';

import { CommonPermissions } from '../../../commonConstants';
import { MessagesPermissions } from '../../../../messages/messagesConstants';
import { ProjectsPermissions } from '../../../../projects/projectsConstants';
import { SearchFiltersScopes } from '../../../../searchFilters/searchFiltersConstants';
import { TasksPermissions } from '../../../../tasks/tasksConstants';

import {
  formsFields,
  projectMemberInvitesKeys,
  projectsKeys,
  tasksKeys,
  words
} from '../../../../../locales/keys';

const tasksLinksPermissions = [
  CommonPermissions.READ_TASKS_AND_PROJECTS_MENU_TASKS_LINK,
  TasksPermissions.READ_TASKS_FAVORITE_FILTER_MENU,
  TasksPermissions.READ_TASKS_BURNING_FILTER_MENU,
  TasksPermissions.READ_TASKS_INVITATIONS_FILTER_MENU,
  TasksPermissions.READ_TASKS_INVITATIONS_FILTER_MENU,
  TasksPermissions.READ_TASKS_RESULTS_MENU,
  TasksPermissions.READ_TASKS_SAVED_FILTERS_MENU,
  TasksPermissions.READ_TASKS_MY_TASKS_FILTER_MENU
];

const projectsLinksPermissions = [
  CommonPermissions.READ_TASKS_AND_PROJECTS_MENU_PROJECTS_LINK,
  ProjectsPermissions.READ_PROJECTS_FAVORITE_FILTER_MENU,
  ProjectsPermissions.READ_PROJECTS_BURNING_FILTER_MENU,
  ProjectsPermissions.READ_PROJECTS_INVITATIONS_FILTER_MENU,
  ProjectsPermissions.READ_PROJECTS_INVOICES_FILTER_MENU,
  ProjectsPermissions.READ_PROJECTS_EVALUATIONS_FILTER_MENU,
  ProjectsPermissions.READ_PROJECTS_SAVED_FILTERS_MENU,
  ProjectsPermissions.READ_PROJECTS_MY_PROJECTS_FILTER_MENU
];

const messagesLinksPermissions = [
  CommonPermissions.READ_TASKS_AND_PROJECTS_MENU_MESSAGES_LINK,
  MessagesPermissions.READ_MESSAGES_SAVED_FILTERS_MENU,
  MessagesPermissions.READ_MESSAGES_UNREAD_FILTER_MENU,
  MessagesPermissions.READ_MESSAGES_MY_MESSAGES_FILTER_MENU,
  MessagesPermissions.READ_MESSAGES_VISIBLE_FOR_CLIENT_FILTER_MENU
];

interface WorkspaceSecondaryMenuProps {
  onDeleteActiveFilter?: () => void;
}

function WorkspaceSecondaryMenu({
  onDeleteActiveFilter
}: WorkspaceSecondaryMenuProps) {
  const {
    pathname,
    query: { slug }
  } = useRouter();

  return (
    <SecondaryMenuWrapper i18nTitle={menuKeys.tabs.workspace}>
      <CheckPermissions actions={tasksLinksPermissions}>
        <div className="p-4">
          <MenuTitle i18nText={menuKeys.links.tasks} />
          <CheckPermissions
            action={CommonPermissions.READ_TASKS_AND_PROJECTS_MENU_TASKS_LINK}
          >
            <MenuPlainLink
              active={pathname === TaskPath.index()}
              href={TaskPath.index()}
              i18nText={tasksKeys.all}
              icon={IconsEnum.VIEW_LIST_SOLID}
            />
          </CheckPermissions>
          <CheckPermissions
            action={TasksPermissions.READ_TASKS_FAVORITE_FILTER_MENU}
          >
            <MenuPlainLink
              active={includes(pathname, TaskPath.favorite())}
              href={TaskPath.favorite()}
              i18nText={words.favorite}
              icon={IconsEnum.FILLED_STAR}
            />
          </CheckPermissions>
          <CheckPermissions
            action={TasksPermissions.READ_TASKS_BURNING_FILTER_MENU}
          >
            <MenuPlainLink
              active={includes(pathname, TaskPath.burning())}
              href={TaskPath.burning()}
              i18nText={formsFields.burning}
              icon={IconsEnum.BURNING}
            />
          </CheckPermissions>
          <CheckPermissions
            action={TasksPermissions.READ_TASKS_MY_TASKS_FILTER_MENU}
          >
            <MenuPlainLink
              active={includes(pathname, TaskPath.myTasks())}
              href={TaskPath.myTasks()}
              i18nText={formsFields.myTasks}
              icon={IconsEnum.USER_SOLID}
            />
          </CheckPermissions>
          <CheckPermissions
            action={TasksPermissions.READ_TASKS_INVITATIONS_FILTER_MENU}
          >
            <MenuPlainLink
              active={includes(pathname, TaskPath.invitations())}
              href={TaskPath.invitations()}
              i18nText={taskMemberInvitesKeys.plural}
              icon={IconsEnum.TICKET_SOLID}
            />
          </CheckPermissions>
          <CheckPermissions action={TasksPermissions.READ_TASKS_RESULTS_MENU}>
            <MenuPlainLink
              active={includes(pathname, TaskPath.itemsResults())}
              href={TaskPath.itemsResults()}
              i18nText={resultsKeys.plural}
              icon={IconsEnum.ROCKET_SOLID}
            />
          </CheckPermissions>
          <CheckPermissions
            action={TasksPermissions.READ_TASKS_SAVED_FILTERS_MENU}
          >
            <SearchFiltersMenuDropdown<FetchTasksFilters>
              activeFilterNanoId={slug as SearchFilterNanoID}
              onDeleteActiveFilter={onDeleteActiveFilter}
              scope={SearchFiltersScopes.TASKS_INDEX}
            />
          </CheckPermissions>
        </div>
      </CheckPermissions>

      <CheckPermissions actions={projectsLinksPermissions}>
        <div className="p-4">
          <MenuTitle i18nText={menuKeys.links.projects} />
          <CheckPermissions
            action={
              CommonPermissions.READ_TASKS_AND_PROJECTS_MENU_PROJECTS_LINK
            }
          >
            <MenuPlainLink
              active={pathname === ProjectPath.index()}
              href={ProjectPath.index()}
              i18nText={projectsKeys.all}
              icon={IconsEnum.VIEW_LIST_SOLID}
            />
          </CheckPermissions>
          <CheckPermissions
            action={ProjectsPermissions.READ_PROJECTS_FAVORITE_FILTER_MENU}
          >
            <MenuPlainLink
              active={includes(pathname, ProjectPath.favorite())}
              href={ProjectPath.favorite()}
              i18nText={words.favorite}
              icon={IconsEnum.FILLED_STAR}
            />
          </CheckPermissions>
          <CheckPermissions
            action={ProjectsPermissions.READ_PROJECTS_BURNING_FILTER_MENU}
          >
            <MenuPlainLink
              active={includes(pathname, ProjectPath.burning())}
              href={ProjectPath.burning()}
              i18nText={formsFields.burning}
              icon={IconsEnum.BURNING}
            />
          </CheckPermissions>
          <CheckPermissions
            action={ProjectsPermissions.READ_PROJECTS_MY_PROJECTS_FILTER_MENU}
          >
            <MenuPlainLink
              active={includes(pathname, ProjectPath.myProjects())}
              href={ProjectPath.myProjects()}
              i18nText={formsFields.myProjects}
              icon={IconsEnum.USER_SOLID}
            />
          </CheckPermissions>
          <CheckPermissions
            action={ProjectsPermissions.READ_PROJECTS_INVITATIONS_FILTER_MENU}
          >
            <MenuPlainLink
              active={includes(pathname, ProjectPath.invitations())}
              href={ProjectPath.invitations()}
              i18nText={projectMemberInvitesKeys.plural}
              icon={IconsEnum.TICKET_SOLID}
            />
          </CheckPermissions>
          <CheckPermissions
            action={ProjectsPermissions.READ_PROJECTS_INVOICES_FILTER_MENU}
          >
            <MenuPlainLink
              active={includes(pathname, ProjectPath.invoices())}
              href={ProjectPath.invoices()}
              i18nText={invoicesKeys.plural}
              icon={IconsEnum.RECEIPT_REFUND}
            />
          </CheckPermissions>
          <CheckPermissions
            action={ProjectsPermissions.READ_PROJECTS_EVALUATIONS_FILTER_MENU}
          >
            <MenuPlainLink
              active={includes(pathname, ProjectPath.evaluations())}
              href={ProjectPath.evaluations()}
              i18nText={evaluationsKeys.plural}
              icon={IconsEnum.STARS_MULTIPLE_SOLID}
            />
          </CheckPermissions>
          <CheckPermissions
            action={ProjectsPermissions.READ_PROJECTS_SAVED_FILTERS_MENU}
          >
            <SearchFiltersMenuDropdown<FetchProjectsFilters>
              activeFilterNanoId={slug as SearchFilterNanoID}
              onDeleteActiveFilter={onDeleteActiveFilter}
              scope={SearchFiltersScopes.PROJECTS_INDEX}
            />
          </CheckPermissions>
        </div>
      </CheckPermissions>

      <CheckPermissions actions={messagesLinksPermissions}>
        <div className="p-4">
          <MenuTitle i18nText={menuKeys.links.messages} />
          <CheckPermissions
            action={
              CommonPermissions.READ_TASKS_AND_PROJECTS_MENU_MESSAGES_LINK
            }
          >
            <MenuPlainLink
              active={pathname === MessagePath.index()}
              href={MessagePath.index()}
              i18nText={messagesKeys.all}
              icon={IconsEnum.VIEW_LIST_SOLID}
            />
          </CheckPermissions>
          <CheckPermissions
            action={MessagesPermissions.READ_MESSAGES_UNREAD_FILTER_MENU}
          >
            <MenuPlainLink
              active={includes(pathname, MessagePath.unread())}
              href={MessagePath.unread()}
              i18nText={messagesKeys.new}
              icon={IconsEnum.BADGE_CHECK}
            />
          </CheckPermissions>
          <CheckPermissions
            action={MessagesPermissions.READ_MESSAGES_MY_MESSAGES_FILTER_MENU}
          >
            <MenuPlainLink
              active={includes(pathname, MessagePath.myMessages())}
              href={MessagePath.myMessages()}
              i18nText={messagesKeys.my}
              icon={IconsEnum.PAPER_AIRPLANE_SOLID}
            />
          </CheckPermissions>
          <CheckPermissions
            action={MessagesPermissions.READ_MESSAGES_ONLY_CLIENTS_FILTER_MENU}
          >
            <MenuPlainLink
              active={includes(pathname, MessagePath.onlyClients())}
              href={MessagePath.onlyClients()}
              i18nText={messagesKeys.fromClients}
              icon={IconsEnum.USER_GROUP_SOLID}
            />
          </CheckPermissions>
          <CheckPermissions
            action={
              MessagesPermissions.READ_MESSAGES_VISIBLE_FOR_CLIENT_FILTER_MENU
            }
          >
            <MenuPlainLink
              active={includes(pathname, MessagePath.visible())}
              href={MessagePath.visible()}
              i18nText={messagesKeys.visible}
              icon={IconsEnum.EYE_SOLID}
            />
          </CheckPermissions>
          <CheckPermissions
            action={MessagesPermissions.READ_MESSAGES_SAVED_FILTERS_MENU}
          >
            <SearchFiltersMenuDropdown<FetchMessagesFilters>
              activeFilterNanoId={slug as SearchFilterNanoID}
              onDeleteActiveFilter={onDeleteActiveFilter}
              scope={SearchFiltersScopes.MESSAGES_INDEX}
            />
          </CheckPermissions>
        </div>
      </CheckPermissions>
    </SecondaryMenuWrapper>
  );
}

export default WorkspaceSecondaryMenu;
