import { FetchProjectsSortTypes } from './projectsTypes';

import { statusesKeys } from '../../locales/keys/models/statuses';

export const INITIAL_PROJECTS_FILTERS = {};
export const INITIAL_PROJECTS_PAGE = 1;
export const INITIAL_PROJECTS_SORT = [FetchProjectsSortTypes.CREATED_AT_DESC];
export const INITIAL_PROJECTS_LIMIT = 50;

export const enum ProjectTabs {
  BRIEF = 'brief',
  TASKS = 'tasks',
  MESSAGES = 'messages',
  ATTACHMENTS = 'attachments',
  RESULTS = 'results',
  TEAM = 'team',
  NOTIFICATIONS = 'notifications',
  TRANSACTIONS = 'transactions',
  INVOICES = 'invoices',
  ACTIVITY = 'activity'
}

export const enum ProjectsPermissions {
  READ_PROJECTS_INDEX_PAGE = 'read_projects_index_page',
  READ_PROJECT_BRIEF_PAGE = 'read_project_brief_page',
  READ_PROJECT_TASKS_PAGE = 'read_project_tasks_page',
  READ_PROJECT_MESSAGES_PAGE = 'read_project_messages_page',
  READ_PROJECT_ATTACHMENTS_PAGE = 'read_project_attachments_page',
  READ_PROJECT_FILES_PAGE = 'read_project_files_page',
  READ_PROJECT_IMAGES_PAGE = 'read_project_images_page',
  READ_PROJECT_PRODUCTS_PAGE = 'read_project_products_page',
  READ_PROJECT_LIFESTYLES_PAGE = 'read_project_lifestyles_page',
  READ_PROJECT_MATERIALS_PAGE = 'read_project_materials_page',
  READ_PROJECT_RESULTS_PAGE = 'read_project_results_page',
  READ_PROJECT_TEAM_PAGE = 'read_project_team_page',
  READ_PROJECT_NOTIFICATIONS_PAGE = 'read_project_notifications_page',
  READ_PROJECT_TRANSACTIONS_PAGE = 'read_project_transactions_page',
  READ_PROJECT_ACCEPT_PAGE = 'read_project_accept_page',
  READ_PROJECT_SEND_INVOICE_BUTTON = 'read_project_send_invoice_button',
  READ_PROJECT_LEAVE_BUTTON = 'read_project_leave_button',
  READ_PROJECT_INVOICES_PAGE = 'read_project_invoices_page',
  READ_PROJECT_ACTIVITY_PAGE = 'read_project_activity_page',
  READ_PROJECTS_SAVED_FILTERS_MENU = 'read_projects_saved_filters_menu',
  READ_PROJECTS_RANKED_NAME_FILTER = 'read_projects_ranked_name_filter',
  READ_PROJECTS_BURNING_FILTER_MENU = 'read_projects_burning_filter_menu',
  READ_PROJECTS_INVITATIONS_FILTER_MENU = 'read_projects_invitations_filter_menu',
  READ_PROJECTS_FAVORITE_FILTER_MENU = 'read_projects_favorite_filter_menu',
  READ_PROJECTS_MY_PROJECTS_FILTER_MENU = 'read_projects_my_projects_filter_menu',
  READ_NEW_PROJECT_BUTTON = 'read_new_project_button',
  READ_PROJECT_PAY_BILLS_BUTTON = 'read_project_pay_bills_button',
  CHANGE_PROJECT_DESCRIPTION = 'change_project_description',
  CHANGE_PROJECT_ESTIMATE_COST = 'change_project_estimate_cost',
  CHANGE_PROJECT_IMPLEMENTATION_DATE = 'change_project_implementation_date',
  CHANGE_PROJECT_IMPLEMENTATION_TIME = 'change_project_implementation_time',
  CHANGE_PROJECT_TASK_NAMES = 'change_project_task_names',
  CHANGE_PROJECT_FILE_ATTACHMENT_IDS = 'change_project_file_attachment_ids',
  READ_PROJECT_ESTIMATE_COST = 'read_project_estimate_cost',
  READ_PROJECT_TASKS_AMOUNTS_SUM = 'read_project_tasks_amounts_sum',
  READ_PROJECT_TASKS_CLIENT_PREPAYMENTS_SUM = 'read_project_tasks_client_prepayments_sum',
  READ_PROJECT_TAGS = 'read_project_tags',
  READ_PROJECT_DEBT = 'read_project_debt',
  READ_PROJECT_CLIENT_DEBT = 'read_project_client_debt',
  READ_PROJECT_COMMISSION = 'read_project_commission',
  READ_PROJECT_CREDIT_COMMISSION = 'read_project_credit_commission',
  READ_PROJECT_HOSTING_COMMISSION = 'read_project_hosting_commission',
  CHANGE_PROJECT_COMMISSION = 'change_project_commission',
  CHANGE_PROJECT_CREDIT_COMMISSION = 'change_project_credit_commission',
  CHANGE_PROJECT_HOSTING_COMMISSION = 'change_project_hosting_commission',
  READ_PROJECT_PROJECT_ACCOUNT = 'read_project_project_account',
  READ_PROJECT_TAG_IDS = 'read_project_tag_ids',
  CHANGE_PROJECT_TAG_IDS = 'change_project_tag_ids',
  READ_PROJECT_STATUS = 'read_project_status',
  READ_PROJECT_TASKS_CLIENT_STATUS = 'read_project_tasks_client_status',
  READ_PROJECTS_TABLE_CHECKBOXES = 'read_projects_table_checkboxes',
  READ_PROJECTS_TABLE_STATUS_FIELD = 'read_projects_table_status_field',
  READ_PROJECTS_TABLE_TASKS_CLIENT_STATUS_FIELD = 'read_projects_table_tasks_client_status_field',
  READ_PROJECT_TRACK_TIME_BUTTON = 'read_project_track_time_button',
  READ_PROJECT_INVITE_USER_BUTTON = 'read_project_invite_user_button',
  READ_PROJECT_FAVORITE_BUTTON = 'read_project_favorite_button',
  READ_PROJECT_TOGGLE_NOTIFICATIONS_BUTTON = 'read_project_toggle_notifications_button',
  READ_PROJECT_UPDATE_NAME_BUTTON = 'read_project_update_name_button',
  READ_PROJECT_UPDATE_DESCRIPTION_BUTTON = 'read_project_update_description_button',
  READ_PROJECT_TEAM_MEMBER_MENU_DROPDOWN = 'read_project_team_member_menu_dropdown',
  READ_PROJECT_MEMBER_TRACKED_TIME = 'read_project_member_tracked_time',
  CREATE_PROJECT_BILL = 'create_project_bill',
  READ_PROJECT_ATTACHMENTS_MATERIALS_FOLDER = 'read_project_attachments_materials_folder',
  READ_PROJECT_CLIENT_MENU_DROPDOWN = 'read_project_client_menu_dropdown',
  READ_PROJECT_ID = 'read_project_id',
  READ_PROJECT_UUID = 'read_project_uuid',
  READ_PROJECT_NANO_ID = 'read_project_nano_id',
  READ_PROJECT_IMPLEMENTATION_DATE = 'read_project_implementation_date',
  CREATE_PROJECT_DESCRIPTION = 'create_project_description',
  CREATE_PROJECT_ESTIMATE_COST = 'create_project_estimate_cost',
  CREATE_PROJECT_IMPLEMENTATION_DATE = 'create_project_implementation_date',
  CREATE_PROJECT_IMPLEMENTATION_TIME = 'create_project_implementation_time',
  CREATE_PROJECT_TASK_NAMES = 'create_project_task_names',
  CREATE_PROJECT_FILE_ATTACHMENT_IDS = 'create_project_file_attachment_ids',
  READ_PROJECT_BILLS_BLOCK = 'read_project_bills_block',
  READ_PROJECT_TRANSACTIONS_ID = 'read_project_transactions_id',
  READ_PROJECT_TRANSACTIONS_CREATED_AT = 'read_project_transactions_created_at',
  READ_PROJECT_TRANSACTIONS_AMOUNT = 'read_project_transactions_amount',
  READ_PROJECT_TRANSACTIONS_TOTAL_BALANCE = 'read_project_transactions_total_balance',
  READ_PROJECT_TRANSACTIONS_AUTHOR = 'read_project_transactions_author',
  READ_PROJECT_TRANSACTIONS_KIND = 'read_project_transactions_kind',
  READ_PROJECT_TRANSACTIONS_BALANCE_TYPE = 'read_project_transactions_balance_type',
  READ_PROJECT_TRANSACTIONS_BALANCE = 'read_project_transactions_balance',
  READ_PROJECT_TRANSACTIONS_PAYMENT_TYPE = 'read_project_transactions_payment_type',
  READ_PROJECT_TRANSACTIONS_TRANSACTION_TAGS = 'read_project_transactions_transaction_tags',
  READ_PROJECT_TRANSACTIONS_DESCRIPTION = 'read_project_transactions_description',
  READ_PROJECT_TASKS_TAB = 'read_project_tasks_tab',
  READ_PROJECT_MESSAGES_TAB = 'read_project_messages_tab',
  READ_PROJECT_BRIEF_TAB = 'read_project_brief_tab',
  READ_PROJECT_ATTACHMENTS_TAB = 'read_project_attachments_tab',
  READ_PROJECT_RESULTS_TAB = 'read_project_results_tab',
  READ_PROJECT_TEAM_TAB = 'read_project_team_tab',
  READ_PROJECT_NOTIFICATIONS_TAB = 'read_project_notifications_tab',
  READ_PROJECT_TRANSACTIONS_TAB = 'read_project_transactions_tab',
  READ_PROJECT_INVOICES_TAB = 'read_project_invoices_tab',
  READ_PROJECT_ACTIVITY_TAB = 'read_project_activity_tab',
  READ_PROJECTS_TAG_IDS_FILTER = 'read_projects_tag_ids_filter',
  READ_PROJECT_CREATE_BILL_BUTTON = 'read_project_create_bill_button',
  CHANGE_PROJECT_ATTACHMENTS_CHECKED = 'change_project_attachments_checked',
  CHANGE_PROJECT_TASK_ATTACHMENTS_CHECKED = 'change_project_task_attachments_checked',
  READ_PROJECT_TRANSACTIONS_DIRECTION = 'read_project_transactions_direction',
  READ_PROJECTS_BURNING_FILTER = 'read_projects_burning_filter',
  CHANGE_PROJECT_FILES_CHECKED = 'change_project_files_checked',
  CHANGE_PROJECT_IMAGES_CHECKED = 'change_project_images_checked',
  CHANGE_PROJECT_TASK_IMAGES_CHECKED = 'change_project_task_images_checked',
  CHANGE_PROJECT_TASK_FILES_CHECKED = 'change_project_task_files_checked',
  CHANGE_PROJECT_TASK_SOURCE_FILES_CHECKED = 'change_project_task_source_files_checked',
  CHANGE_PROJECT_LIFESTYLES_CHECKED = 'change_project_lifestyles_checked',
  CHANGE_PROJECT_MATERIALS_CHECKED = 'change_project_materials_checked',
  CHANGE_PROJECT_PRODUCTS_CHECKED = 'change_project_products_checked',
  CHANGE_PROJECT_TASK_MATERIALS_CHECKED = 'change_project_task_materials_checked',
  CHANGE_PROJECT_TASK_PRODUCTS_CHECKED = 'change_project_task_products_checked',
  CHANGE_PROJECT_TASK_LIFESTYLES_CHECKED = 'change_project_task_lifestyles_checked',
  READ_PROJECTS_SEARCH_FILTERS_POPOVER = 'read_projects_search_filters_popover',
  READ_PROJECTS_EVALUATE_PROJECTS_BLOCK = 'read_projects_evaluate_projects_block',
  READ_PROJECT_BRIEF_REMOVE_SCOPE_OF_WORK_MESSAGE_BUTTON = 'read_project_brief_remove_scope_of_work_message_button',
  READ_PROJECTS_EVALUATIONS_FILTER_MENU = 'read_projects_evaluations_filter_menu',
  READ_PROJECT_SIDEBAR_FILE_ATTACHMENT_EDIT_BUTTON = 'read_project_sidebar_file_attachment_edit_button',
  READ_PROJECT_ATTACHMENTS_FILE_ATTACHMENT_EDIT_BUTTON = 'read_project_attachments_file_attachment_edit_button',
  READ_PROJECTS_INVOICES_FILTER_MENU = 'read_projects_invoices_filter_menu',
  READ_PROJECTS_BATCH_CREATE_PROJECTS_BILLS_BUTTON = 'read_projects_batch_create_projects_bills_button',
  READ_PROJECTS_BATCH_INVITE_USERS_TO_PROJECTS_BUTTON = 'read_projects_batch_invite_users_to_projects_button',
  READ_PROJECTS_BATCH_UPDATE_AMOUNTS_BUTTON = 'read_projects_batch_update_amounts_button',
  READ_PROJECT_INVITED_MEMBERS_TABLE = 'read_project_invited_members_table',
  READ_PROJECT_INVITED_MEMBERS_TABLE_DELETE_BUTTON = 'read_project_invited_members_table_delete_button',
  READ_PROJECT_CREATE_MESSAGE_FORM_FULLSCREEN_DROPZONE = 'read_project_create_message_form_fullscreen_dropzone',
  READ_PROJECT_CREATE_MESSAGE_FORM_PASTE_FILES = 'read_project_create_message_form_paste_files',
  READ_PROJECT_TASKS_TABLE_SETTINGS_BUTTON = 'read_project_tasks_table_settings_button',
  READ_PROJECT_TASKS_HIDDEN_FILTER = 'read_project_tasks_hidden_filter',
  READ_PROJECT_TASKS_CANCELED_FILTER = 'read_project_tasks_canceled_filter',
  READ_PROJECT_SUBMIT_FILE_ATTACHMENTS_MODAL_BUTTON_FULLSCREEN_DROPZONE = 'read_project_submit_file_attachments_modal_button_fullscreen_dropzone'
}

export const projectsStatuses = [
  { value: 'acceptance', i18nLabel: statusesKeys.types.acceptance },
  { value: 'canceled', i18nLabel: statusesKeys.types.canceled },
  { value: 'definition', i18nLabel: statusesKeys.types.definition },
  {
    value: 'implementation',
    i18nLabel: statusesKeys.types.implementation
  },
  {
    value: 'incomplete_specification',
    i18nLabel: statusesKeys.types.incompleteSpecification
  },
  { value: 'performed', i18nLabel: statusesKeys.types.performed },
  // { value: 'paused', i18nLabel: statusesKeys.types.paused },
  { value: 'qa', i18nLabel: statusesKeys.types.qa }
];
