import React, { SyntheticEvent, useCallback, useState, useEffect } from 'react';
import cl from 'classnames';
import size from 'lodash/size';
import some from 'lodash/some';

import {
  SearchFilterFields,
  SearchFilterNanoID
} from '../../../searchFiltersTypes';

import { useSearchFilters } from '../../../hooks/useSearchFilters';

import { SearchFiltersListItem } from '../../SearchFiltersListItem';

import {
  FETCH_SEARCH_FILTERS_QUERY,
  FetchSearchFiltersQueryResponse
} from '../../../queries/fetchSearchFilters.query';

import { SearchFilterCache } from '../../../SearchFilterCache';
import { SearchFiltersScopes } from '../../../searchFiltersConstants';

import { ButtonHelper } from '../../../../../helpers/buttons/ButtonHelper';
import { Icon } from '../../../../../helpers/Icon';
import { IconsEnum } from '../../../../../assets/icons/types';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { NoResults } from '../../../../../helpers/NoResults';
import { Translate } from '../../../../../helpers/Translate';

import { words } from '../../../../../locales/keys';

interface SearchFiltersMenuDropdownProps {
  activeFilterNanoId?: SearchFilterNanoID;
  onDeleteActiveFilter?: () => void;
  scope: SearchFiltersScopes;
}

function SearchFiltersMenuDropdown<FiltersType>({
  activeFilterNanoId,
  onDeleteActiveFilter,
  scope
}: SearchFiltersMenuDropdownProps) {
  const { searchFilters, searchFiltersFetched, searchFiltersError } =
    useSearchFilters<FetchSearchFiltersQueryResponse<FiltersType>>({
      cacheKey: SearchFilterCache.indexCacheKey(),
      query: FETCH_SEARCH_FILTERS_QUERY,
      initialFilters: { scope: { eq: scope }, self: true }
    });

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsOpen(
      some(searchFilters, [SearchFilterFields.NANO_ID, activeFilterNanoId])
    );
  }, [activeFilterNanoId, searchFilters]);

  const handleClick = useCallback<(e: SyntheticEvent) => void>(
    (e: SyntheticEvent) => {
      e.preventDefault();
      setIsOpen((prevVal) => !prevVal);
    },
    [setIsOpen]
  );

  return (
    <div className="-mx-4">
      <div className="flex items-center group relative px-4 py-2 transition-colors ease-in-out duration-150 focus-within:ring-2 focus-within:ring-gray-850 dark:focus-within:ring-gray-100 bg-gray-500 bg-opacity-0 hover:bg-opacity-20">
        <div className="pl-2 pr-1">
          <Icon
            icon={IconsEnum.FILTER_SOLID}
            className="h-5 w-5 flex-shrink-0"
          />
        </div>
        <div className="absolute left-1 inset-y-0 flex items-center">
          <Icon
            icon={IconsEnum.CHEVRON_RIGHT}
            className={cl(
              'text-current h-5 w-5 transform flex-shrink-0',
              isOpen ? 'rotate-90' : null
            )}
          />
        </div>
        <ButtonHelper
          className="flex-1 px-2 text-sm text-left focus:ring-0 focus:ring-offset-0"
          onClick={handleClick}
        >
          <Translate id={words.savedFilters} />
          <span className="absolute inset-0" aria-hidden="true" />
        </ButtonHelper>
      </div>

      {isOpen ? (
        <LoadingSkeleton count={2} loaded={searchFiltersFetched}>
          {size(searchFilters) ? (
            <ul className="mt-2 mb-4 px-4">
              {searchFilters.map(({ colors, name, nanoId }) => (
                <SearchFiltersListItem
                  active={activeFilterNanoId === nanoId}
                  color={colors?.[0]}
                  i18nText={name}
                  key={nanoId}
                  onDeleteActiveFilter={onDeleteActiveFilter}
                  scope={scope}
                  searchFilterNanoId={nanoId}
                />
              ))}
            </ul>
          ) : (
            <NoResults errorMessage={searchFiltersError} />
          )}
        </LoadingSkeleton>
      ) : null}
    </div>
  );
}

export default SearchFiltersMenuDropdown;
